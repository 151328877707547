import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36')
];

export const server_loads = [0,2,3,4,5];

export const dictionary = {
		"/(admin)": [~6,[2]],
		"/(admin)/api/v1/tickets/[id]/download": [7,[2]],
		"/(admin)/api/v1/tickets/[id]/print": [8,[2]],
		"/(admin)/audit-logs": [~9,[2]],
		"/(admin)/error": [~10,[2]],
		"/(admin)/layouts": [~11,[2]],
		"/(admin)/layouts/[layout]": [~12,[2]],
		"/login": [~35],
		"/logout": [~36],
		"/(admin)/mails": [~13,[2]],
		"/(admin)/mails/[mail]": [~14,[2]],
		"/(admin)/migration/01": [~15,[2]],
		"/(admin)/migration/02": [~16,[2]],
		"/(admin)/migration/03": [~17,[2]],
		"/(admin)/migration/04/[skip]": [~18,[2]],
		"/(admin)/migration/05": [~19,[2]],
		"/(admin)/offices": [~20,[2]],
		"/(admin)/offices/[office]": [~21,[2]],
		"/(admin)/orders": [~22,[2]],
		"/(admin)/orders/[order]": [~23,[2]],
		"/(admin)/organizations": [~24,[2]],
		"/(admin)/organizations/[organization]": [~25,[2,3]],
		"/(admin)/organizations/[organization]/series/[series]/editions/[edition]": [26,[2,3,4]],
		"/(admin)/organizations/[organization]/series/[series]/editions/[edition]/events/[event]": [27,[2,3,4,5]],
		"/(admin)/print-jobs": [~28,[2]],
		"/(admin)/qrcode": [29,[2]],
		"/(admin)/registers": [~30,[2]],
		"/(admin)/scans": [~31,[2]],
		"/(admin)/scans/failed": [~33,[2]],
		"/(admin)/scans/[ticketId]": [~32,[2]],
		"/(admin)/status": [~34,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';